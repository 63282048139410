define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"quickViewAlert\">\n	<p>\n		";
  stack1 = (helper = helpers.rawHTML || (depth0 && depth0.rawHTML),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.ajaxBasket)),stack1 == null || stack1 === false ? stack1 : stack1.errors)),stack1 == null || stack1 === false ? stack1 : stack1.basketError), options) : helperMissing.call(depth0, "rawHTML", ((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.props)),stack1 == null || stack1 === false ? stack1 : stack1.ajaxBasket)),stack1 == null || stack1 === false ? stack1 : stack1.errors)),stack1 == null || stack1 === false ? stack1 : stack1.basketError), options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n	</p>\n</div>";
  return buffer;
  }); });